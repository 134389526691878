<template>
  <div />
</template>
<script>
export default {
  name: "Logout",
  async created() {
    await this.$rest.login.resetRememberMe();
    await this.$rest.login.logout();
    localStorage.setItem("manualLogout", "true");

    this.$store.commit('user/clearLogoutCheck');

    await this.$router.push({name: 'Login'})
  }
}
</script>
